import { themes } from './themeData'
const changeStyle = (obj) => {
  for (const key in obj) {
    document
      .getElementsByTagName('body')[0]
      .style.setProperty(`--${key}`, obj[key])
  }
}
export const setTheme = (themeName) => {
  const themeConfig = themes[themeName]
  if (themeConfig) {
    changeStyle(themeConfig)
  }
}
