import axios from '../request'
import qs from 'qs'
import { commonFormatDown } from '@/util'

// 全球地区列表
export function getAreaPub (data) {
  return axios({
    url: `/basic/getAreasPub`,
    method: 'post',
    data
  })
}

// 收款va币种
export function getReceiveVaCur (data) {
  return axios({
    url: `/receiveVA/getVaCur`,
    method: 'post',
    data
  })
};

// 收款账户列表
export function queryVaList (data) {
  return axios({
    url: `/receiveVA/queryVAList`,
    method: 'post',
    data
  })
}

// va类型
export function getBusinessChildType (params) {
  return axios({
    url: `/receiveVA/getBusinessChildType`,
    method: 'post',
    params
  })
}

// 收款地区
export const getReceiveAreas = (data) => {
  return axios({
    url: `/receiveVA/getReceiveAreas`,
    method: 'post',
    data
  })
}

// 特殊币种
export const getPlatCurrency = (data) => {
  return axios({
    url: `/receiveVA/getPlatCurrency`,
    method: 'post',
    data
  })
}

// 地区支持币种
export const getReceiveCurrencys = (data) => {
  return axios({
    url: `/receiveVA/getReceiveCurrencys`,
    method: 'post',
    data
  })
}

// 提交va
export const applyToVa = (data) => {
  return axios({
    url: `/receiveVA/applyToVa`,
    method: 'post',
    data
  })
}

// va账户详情
export const geVaDetail = (params) => {
  return axios({
    url: `/receiveVA/queryVADetail`,
    method: 'post',
    params
  })
}

// 开户银行
export const getBankOfDeposit = (data) => {
  return axios({
    url: `/receiveVA/getIsSameName`,
    method: 'post',
    data
  })
}

// 下载账户列表
export const exportAccountList = async (data) => {
  const res = await axios({
    url: `/receiveVA/exportVaList`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 所有账户列表
export const getAllVAs = () => {
  return axios({
    url: `/receiveTrade/queryAllVA`,
    method: 'get'
  })
}

// 收款管理列表
export const getReceiveFlows = (data) => {
  return axios({
    url: `/receiveTrade/queryAllReceiveFlowPage`,
    method: 'post',
    data
  })
}

// 结算申请币种列表
export const getReceiveRateCur = (params) => {
  return axios({
    url: `/receiveTrade/getReceiveRateCur`,
    method: 'post',
    params
  })
}

// 结算申请提交
export const tradeExchangeApply = (data) => {
  return axios({
    url: `/receiveTrade/tradeExchangeApply`,
    method: 'post',
    data
  })
}

// 结算详情
export const getSettleInfo = (params) => {
  return axios({
    url: `/receiveTrade/getSettleInfo`,
    method: 'post',
    params
  })
}

// 结算失败原因
export const getRejectReason = (params) => {
  return axios({
    url: '/receiveTrade/getSettleRejectInfo',
    method: 'post',
    params
  })
}

// 提前提交还原材料按钮控制
export const getUploadUsed = () => {
  return axios({
    url: `/receiveTrade/getUploadUsed`,
    method: 'post'
  })
}

// 下载收款明细
export const exportTradeFlow = async (data) => {
  const res = await axios({
    url: `/receiveTrade/tradeFlowExport`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 校验上传文件类型
export const uploadOrderXls = (data) => {
  return axios({
    url: `/pay/uploadOrderXls`,
    method: 'post',
    data
  })
}

// 获取全部币种
export const getAllCurrency = () => {
  return axios({
    url: `/receiveTrade/listAllCurrency`,
    method: 'get'
  })
}

// 服贸/货贸还原材料提交
export const receiveTradeOrderSave = (data) => {
  return axios({
    url: `/receiveTrade/saveNewReceiveTradeOrder`,
    method: 'post',
    data
  })
}

// 提交材料详情
export const getReceiveTradeDetailByNo = (params) => {
  return axios({
    url: `/receiveTrade/queryReceiveTradeFlowDetailByNo`,
    method: 'get',
    params
  })
}

// 获取入账金额
export const getAccountLimit = (params) => {
  return axios({
    url: `/receiveTrade/getAccountLimit`,
    method: 'get',
    params
  })
}

// 获取账户详情
export const getAccountDetail = (params) => {
  return axios({
    url: `/receiveVA/getAbilityInfos`,
    method: 'post',
    params
  })
}

// 获取店铺信息
export const getShopDetail = (data) => {
  return axios({
    url: `/receiveVA/getShopDetails`,
    method: 'post',
    data
  })
}

// 修改账户简称
export const updateSimpleName = (data) => {
  return axios({
    url: `/receiveVA/editSimpleName`,
    method: 'post',
    data
  })
}

// 账户持有人列表页
export const queryHolderPage = (data) => {
  return axios({
    url: `/receiveHolder/queryHolderPage`,
    method: 'post',
    data
  })
}

// 账户持有人新增
export const addReceiveHolder = (data) => {
  return axios({
    url: `/receiveHolder/addReceiveHolder`,
    method: 'post',
    data
  })
}

// 账户持有人重新申请
export const reAddReceiveHolder = (data) => {
  return axios({
    url: `/receiveHolder/reAddReceiveHolder`,
    method: 'post',
    data
  })
}

// 账户持有人详情
export const queryHolderDetail = (params) => {
  return axios({
    url: `/receiveHolder/queryHolderDetail`,
    method: 'get',
    params
  })
}

// 账户持有人编辑详情
export const queryReHolderDetail = (params) => {
  return axios({
    url: `/receiveHolder/queryReHolderDetail`,
    method: 'get',
    params
  })
}

// 账户持有人下拉列表
export const queryHolderList = () => {
  return axios({
    url: `/receiveHolder/queryHolderList`,
    method: 'get'
  })
}

// 下载开户证明
export const downOpenCert = async (data) => {
  const res = await axios({
    url: `/receiveVA/downloadAccountInfo`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 下载电子回单
export const downReceiveReceipt = async (data) => {
  const res = await axios({
    url: `/receiveTrade/downloadReceiveInfo`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}
