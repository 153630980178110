import { _home } from '@/api'
const menu = {
  state: {
    menuList: [],
    curList: []
  },
  getters: {
    menuList(state) {
      return state.menuList.length !== 0 ? state.menuList : localStorage.getItem('menuList') ? JSON.parse(localStorage.getItem('menuList')) : state.menuList
    },
    curList(state) {
      return state.curList
    }
  },
  mutations: {
    updateMenuList(state, record) {
      localStorage.setItem('menuList', JSON.stringify(record))
      state.menuList = record
    },
    updateCurList(state, record) {
      state.curList = record
    }
  },
  actions: {
    async getCurList({ commit }) {
      const res = await _home.getCurrency()
      if (res.data.succ) commit('updateCurList', res.data.data.slice(0, 4))
    }
  }
}

export default menu
