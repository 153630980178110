<template>
  <div>
    <a-menu
      id="leftMenu"
      v-model:selectedKeys="selectedKeys"
      v-model:openKeys="openKeys"
      mode="inline"
      theme="dark"
      :inline-collapsed="collapsed"
      :style="{width: collapsed ? '' : '228px'}"
      @click="menuLink"
    >
      <div class="menu-logo"><img :src="themeData.lightLogoList"></div>
      <template v-for="menu in menus">
        <a-menu-item
          v-if="!menu.leaves"
          :key="menu.linkTo"
        >
          <template #icon>
            <img class="menu-icon" :src="menu.icon">
          </template>
          <span>{{ menu.title || menu.name }}</span>
        </a-menu-item>
        <a-sub-menu
          v-else
          :key="menu.subKey"
        >
          <template #icon>
            <img :class="menu.id === 14 ? 'menu-icon-exchange' : menu.id === 17 ? 'menu-icon-manage' : 'menu-icon'" :src="menu.icon">
          </template>
          <template #title>
            <span :class="menu.id === 14 ? 'menu-text-exchange' : menu.id === 17 ? 'menu-text-manage' : ''">{{ menu.title || menu.name }}</span>
          </template>
          <a-menu-item
            v-for="leave in menu.leaves"
            :key="leave.linkTo"
          >
            {{ leave.title || leave.name }}
          </a-menu-item>
        </a-sub-menu>
      </template>
      <a-button
        type="text"
        class="btn-collapse"
        @click="toggleCollapsed"
      >
        <LogoutOutlined v-if="collapsed" />
        <LoginOutlined v-else />
      </a-button>
    </a-menu>
    <a-modal
      :visible="visible"
      :mask-closable="false"
      :title="$t('pages_main_041')"
      :confirm-loading="loading"
      @ok="passwordUpdate"
      @cancel="updateCancel"
    >
      <a-form ref="editRef" label-align="left" :model="editForm" :rules="editRules" :label-col="{span: 5}">
        <a-form-item :wrapper-col="{ offset: 5 }" style="margin-top: -8px">
          <div style="font-weight: 500">{{ $t('pages_user_066') }}</div>
          <div style="margin-top: 8px">{{ $t('pages_user_067') }}</div>
          <div style="margin-top: 8px">{{ $t('pages_user_068') }}</div>
          <div style="margin-top: 8px">{{ $t('pages_user_069') }}</div>
        </a-form-item>
        <a-form-item name="oldPassword" :label="$t('pages_main_042')">
          <a-input-password
            v-model:value="editForm.oldPassword"
            :placeholder="$t('common_text_006') + $t('pages_main_042')"
          />
        </a-form-item>
        <a-form-item name="newPassword" :label="$t('pages_user_058')">
          <a-input-password
            v-model:value="editForm.newPassword"
            :placeholder="$t('common_text_006') + $t('pages_user_058')"
          />
        </a-form-item>
        <a-form-item name="secPassword" :label="$t('pages_user_059')">
          <a-input-password
            v-model:value="editForm.secPassword"
            :placeholder="$t('common_text_006') + $t('pages_user_059')"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, toRefs, watch, inject, createVNode } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { Modal, Checkbox, message } from 'ant-design-vue'
import { _home, _user } from '@/api'
import { dealRouterMenus, localStorageClear } from '@/util'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import Cookies from 'js-cookie'
import i18n from '@/locale'
export default defineComponent({
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const editRef = ref(null)

    const validPasswordFormat = (rule, value) => {
      const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/
      if (!value) return Promise.reject(i18n.global.t(rule.field === 'newPassword' ? 'pages_user_058' : 'pages_user_059') + i18n.global.t('common_text_042'))
      if (!pattern.test(value)) return Promise.reject(i18n.global.t('pages_user_060'))
      if (rule.field === 'secPassword' && value !== state.editForm.newPassword) return Promise.reject(i18n.global.t('pages_user_061'))
      return Promise.resolve()
    }

    const state = reactive({
      themeData: inject('$themeData'),
      userInfo: {},
      loading: false,
      collapsed: false,
      visible: false,
      agreementList: [],
      openKeys: [`/${route.path.split('/')[1]}`],
      selectedKeys: [route.path],
      model: null,
      menus: dealRouterMenus(store.getters.menuList),
      editForm: {
        oldPassword: '',
        newPassword: '',
        secPassword: ''
      },
      editRules: {
        oldPassword: [{ required: true }],
        newPassword: [{ required: true, validator: validPasswordFormat }],
        secPassword: [{ required: true, validator: validPasswordFormat }]
      }
    })

    watch(
      () => router.currentRoute.value,
      (route) => {
        if (!state.collapsed) {
          state.openKeys = [`/${route.path.split('/')[1]}`]
          state.selectedKeys = [route.path]
        }
        if (!store.getters.statusAll) store.dispatch('getLanguage')
      },
      { immediate: true }
    )

    const toggleCollapsed = () => {
      state.collapsed = !state.collapsed
    }

    const menuLink = ({ key }) => {
      init()
      router.push(key || '/error404')
    }

    const signAgree = async () => {
      const res = await _home.updateAgreement()
      if (res.data.succ) {
        state.userInfo.agreeStatus = '00'
        await store.commit('updateUserInfo', state.userInfo)
        init()
      }
    }

    const updateCancel = () => {
      state.visible = false
      localStorage.setItem('updatePassword', true)
    }

    const passwordUpdate = () => {
      editRef.value.validateFields()
        .then(() => {
          state.loading = true
          const { oldPassword, newPassword } = state.editForm
          return _user.modifyPassword({ oldPassword, newPassword })
        })
        .then(res => {
          state.loading = false
          if (res.data.succ) {
            message.success(i18n.global.t('common_text_022'))
            localStorageClear()
            router.replace('/login')
          } else {
            message.error(res.data.resMsg)
          }
        })
        .catch(err => console.log(err))
    }

    const showAgreeModal = () => {
      const createVNodes = [createVNode('span', {}, i18n.global.t('pages_main_031'))]
      if (process.env.VUE_APP_SAASNAME !== 'OTT') {
        state.agreementList.map(item => {
          createVNodes.push(createVNode('a', { href: `#/agreement/${item.agreementKey}`, target: '_blank' }, `《${item.agreementName}》`))
        })
      } else {
        createVNodes.push(createVNode('a', { href: '#/terms_service', target: '_blank' }, i18n.global.t('pages_main_032')))
        createVNodes.push(createVNode('a', { href: '#/privacy_policy', target: '_blank' }, i18n.global.t('pages_main_033')))
      }
      state.modal = Modal.confirm({
        title: i18n.global.t('pages_main_029'),
        icon: createVNode(ExclamationCircleOutlined),
        width: 500,
        okButtonProps: { disabled: true },
        content: createVNode('div', {},
          [
            createVNode('div', {}, i18n.global.t('pages_main_030')),
            createVNode(Checkbox, { style: { marginTop: '24px' }, onChange: e => state.modal.update({ okButtonProps: { disabled: !e.target.checked }}) }, createVNodes)
          ]
        ),
        async onOk() {
          await signAgree()
        },
        onCancel() {
          router.replace('/home/index')
        }
      })
    }

    const loadMerchantInfo = async () => {
      const res = await _home.getMerchantInfo()
      if (res.data.succ) {
        const { status } = res.data.data
        if (status === 'CUSTOMER') {
          state.modal = Modal.confirm({
            title: i18n.global.t('pages_main_056', [i18n.global.locale.value === 'zh' ? state.themeData.saasMerNameZh : state.themeData.saasMerNameEn]),
            icon: createVNode(ExclamationCircleOutlined),
            width: 540,
            okText: i18n.global.t('pages_main_036'),
            onOk() {
              router.push('/guide_page')
            },
            onCancel() {
              router.replace('/home/index')
            }
          })
        } else if (status === 'CUSTREJ') {
          state.modal = Modal.confirm({
            title: i18n.global.t('pages_main_037'),
            icon: createVNode(ExclamationCircleOutlined, { style: { color: '#ff4d4f' }}),
            width: 500,
            okText: i18n.global.t('pages_main_038'),
            onOk() {
              router.push('/merchant_info')
            },
            onCancel() {
              router.replace('/home/index')
            }
          })
        } else if (status !== 'OPENFINSH') {
          state.modal = Modal.info({
            title: i18n.global.t('pages_main_039'),
            icon: createVNode(ExclamationCircleOutlined),
            width: 500,
            content: createVNode('div', { style: { marginTop: '24px' }}, i18n.global.t('pages_main_040')),
            onOk() {
              router.replace('/home/index')
            }
          })
        } else {
          state.visible = state.userInfo.first && !localStorage.getItem('updatePassword')
        }
      }
    }

    const loadSaasMerAgreement = async () => {
      const res = await _user.getSaasMerAgreement({})
      if (res.data.succ) state.agreementList = res.data.data
    }

    const init = async () => {
      if (state.menus.length === 0) {
        Cookies.remove('last_page_name')
        localStorageClear()
        router.replace('/login')
      }
      if (process.env.VUE_APP_SAASNAME !== 'OTT') await loadSaasMerAgreement()
      state.userInfo = store.getters.getUserInfo
      if (state.userInfo.agreeStatus === '01') showAgreeModal()
      else if (state.userInfo.agreeStatus === '02') signAgree()
      else loadMerchantInfo()
    }

    init()

    return {
      editRef,
      menuLink,
      updateCancel,
      passwordUpdate,
      toggleCollapsed,
      ...toRefs(state)
    }
  }
})
</script>
<style lang="less">
#leftMenu{
  .scrollbar-none;
  height: 100vh;
  overflow-y: scroll;
  background: @menu-bg-color;
  border-right: 0;
  .menu-logo {
    // width: 65%;
    // margin: 15% 11% 12% 11%;
    height: 60px;
    // align-items: center;
    //border-bottom: 1px solid #E8E8E8;
    display: flex;
    // 垂直居中，水平居左
    // justify-content: center;
    // align-items: center;

    img {
      margin: 10px 0 0 10px;
      width: 130px;
      height: 35px;
    }
  }
  .menu-icon {
    width: 20px;
    height: 20px;
    margin-left: -6px;
  }
  .menu-icon-exchange {
    width: 20px;
    height: 20px;
    margin-left: -6px;
  }
  .menu-icon-manage {
    width: 20px;
    height: 20px;
    margin-left: -6px;
  }
  // .menu-text-exchange {
  //   margin-left: 2px;
  // }
  // .menu-text-manage {
  //   margin-left: -1px;
  // }
  .btn-collapse {
    display: block;
    font-size: 18px;
    color: #fff;
    margin: 188px 0 0 16px;
  }
}
#leftMenu::-webkit-scrollbar {
  display: none;
}
</style>
