const axios = require('axios')

const instance = axios.create({
})

instance.interceptors.request.use(function (config) {
  const frontKey = process.env.VUE_APP_SAASNAME !== 'undefined' ? process.env.VUE_APP_SAASNAME : 'OTT'
  config.withCredentials = true
  config.headers['Front-Key'] = frontKey
  return config
}, function (error) {
  return Promise.reject(error)
})

module.exports = instance
