import MainPage from '../pages/index'
import MaterialSubmit from '../pages/global-receive/manage/material-submit'
import i18n from '../locale/index'

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: { title: i18n.global.t('pages_router_001') },
    component: () => import('@/pages/login')
  },
  {
    path: '/register',
    name: 'register',
    meta: { title: i18n.global.t('pages_router_039') },
    component: () => import('@/pages/register')
  },

  {
    path: '/oauth',
    name: 'oauth',
    children: [{
      path: '/oauth/oauthLogin',
      name: 'oauthLogin',
      meta: { title: i18n.global.t('pages_router_045') },
      component: () => import('@/pages/oauth/oauth-login.vue')
    },
    {
      path: '/oauth/oauthSubmit',
      name: 'oauthSubmit',
      meta: { title: i18n.global.t('pages_router_046') },
      component: () => import('@/pages/oauth/oauth-submit.vue')
    }]
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    meta: { title: i18n.global.t('pages_router_040') },
    component: () => import('@/pages/reset-password')
  },
  {
    path: '/user_leave',
    name: 'userLeave',
    meta: { title: i18n.global.t('common_text_054') },
    component: () => import('@/pages/user-leave')
  },
  {
    path: '/online_fix',
    name: 'onlineFix',
    meta: { title: i18n.global.t('common_text_049') },
    component: () => import('@/pages/online-fix')
  },
  {
    path: '/guide_page',
    name: 'guidePage',
    meta: { title: i18n.global.t('pages_router_038') },
    component: () => import('@/pages/merchant-info/guide-page')
  },
  {
    path: '/agreement/:key',
    name: 'agreement',
    meta: { title: i18n.global.t('pages_router_051') },
    component: () => import('@/pages/agreement/index.vue')
  },
  {
    path: '/guide_success',
    name: 'guideSuccess',
    meta: { title: i18n.global.t('pages_router_026') },
    component: () => import('@/pages/merchant-info/guide-success')
  },
  {
    path: '/terms_service',
    name: 'termsService',
    meta: { title: i18n.global.t('pages_router_041') },
    component: () => {
      if (localStorage.getItem('locale-language') === 'zh') return import('@/pages/terms-service/cn.vue')
      else return import('@/pages/terms-service/en.vue')
    }
  },
  {
    path: '/clause_supply',
    name: 'clauseSupply',
    meta: { title: i18n.global.t('pages_router_043') },
    component: () => import('@/pages/terms-service/supply')
  },
  {
    path: '/privacy_policy',
    name: 'privacyPolicy',
    meta: { title: i18n.global.t('pages_router_042') },
    component: () => {
      if (localStorage.getItem('locale-language') === 'zh') return import('@/pages/privacy-policy/cn')
      else return import('@/pages/privacy-policy/en')
    }
  },
  {
    path: '/use_policy',
    name: 'usePolicy',
    meta: { title: i18n.global.t('pages_router_044') },
    component: () => {
      if (localStorage.getItem('locale-language') === 'zh') return import('@/pages/use-policy/cn')
      else return import('@/pages/use-policy/en')
    }
  },
  {
    path: '/merchant_info',
    name: 'merchantInfo',
    meta: { title: i18n.global.t('pages_router_037') },
    component: () => import('@/pages/merchant-info')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/pages/error-404'),
    meta: { title: i18n.global.t('pages_router_011') }
  },
  {
    order: 1,
    path: '/home',
    name: 'home',
    id: 11,
    icon: require('@/assets/icons/ic-index.png'),
    component: MainPage,
    children: [
      {
        id: 1101,
        meta: { title: i18n.global.t('pages_router_002') },
        path: '/home/index',
        name: 'homeIndex',
        component: () => import('@/pages/home-index')
      }
    ]
  },
  {
    order: 2,
    path: '/wallet',
    name: 'wallet',
    id: 12,
    icon: require('@/assets/icons/ic-wallet.png'),
    component: MainPage,
    children: [
      {
        id: 1201,
        meta: { title: i18n.global.t('pages_router_022') },
        path: '/wallet/index',
        name: 'walletIndex',
        component: () => import('@/pages/global-wallet')
      }
    ]
  },
  {
    order: 4,
    path: '/receive',
    name: 'receive',
    id: 13,
    icon: require('@/assets/icons/ic-receive.png'),
    meta: { title: i18n.global.t('pages_router_003') },
    component: MainPage,
    children: [
      {
        id: 1301,
        meta: { title: i18n.global.t('pages_router_006') },
        path: '/receive/manage',
        name: 'receiveManage',
        component: () => import('@/pages/global-receive/manage')
      },
      {
        id: 1302,
        meta: { title: i18n.global.t('pages_router_004') },
        path: '/receive/account',
        name: 'receiveAccount',
        component: () => import('@/pages/global-receive/account')
      },
      {
        id: 1303,
        meta: { title: i18n.global.t('pages_router_005') },
        path: '/receive/apply_type',
        name: 'applyFill',
        component: () => import('@/pages/global-receive/account/apply-type')
      },
      {
        id: 1304,
        meta: { title: i18n.global.t('pages_router_005') },
        path: '/receive/e_commerce',
        name: 'applyEcommerce',
        component: () => import('@/pages/global-receive/account/e-commerce-form')
      },
      {
        id: 1305,
        meta: { title: i18n.global.t('pages_router_005') },
        path: '/receive/t_trade',
        name: 'applyTrade',
        component: () => import('@/pages/global-receive/account/t-trade-form')
      },
      {
        id: 1306,
        meta: { title: i18n.global.t('pages_router_026') },
        path: '/receive/apply_success',
        name: 'applySuccess',
        component: () => import('@/pages/global-receive/account/apply-success')
      },
      {
        id: 1307,
        meta: { title: i18n.global.t('pages_router_009') },
        path: '/receive/material_submit',
        name: 'materialSubmit',
        component: MaterialSubmit,
        children: [
          {
            id: 13071,
            meta: { title: i18n.global.t('pages_router_009') },
            path: '/receive/material_submit/e_commerce/',
            name: 'materialEcommerce',
            component: () => import('@/pages/global-receive/manage/material-submit/e-commerce')
          },
          {
            id: 13072,
            meta: { title: i18n.global.t('pages_router_009') },
            path: '/receive/material_submit/t_trade',
            name: 'materialTrade',
            component: () => import('@/pages/global-receive/manage/material-submit/t-trade')
          }
        ]
      },
      {
        id: 1308,
        meta: { title: i18n.global.t('pages_receive_120') },
        path: '/receive/account_detail',
        name: 'accountDetail',
        component: () => import('@/pages/global-receive/account/trade-detail')
      },
      {
        id: 1309,
        meta: { title: i18n.global.t('pages_router_047') },
        path: '/receive/account_holder',
        name: 'accountHolder',
        component: () => import('@/pages/global-receive/holder')
      },
      {
        id: 1310,
        meta: { title: i18n.global.t('pages_router_048') },
        path: '/receive/holder_edit',
        name: 'holderEdit',
        component: () => import('@/pages/global-receive/holder/holder-edit')
      }
    ]
  },
  {
    order: 5,
    path: '/exchange',
    name: 'exchange',
    id: 14,
    icon: require('@/assets/icons/ic-tab-exchange.png'),
    meta: { title: i18n.global.t('pages_router_016') },
    component: MainPage,
    children: [
      {
        id: 1401,
        meta: { title: i18n.global.t('pages_router_017') },
        path: '/exchange/apply',
        name: 'exchangeApply',
        component: () => import('@/pages/currency-exchange/apply')
      },
      {
        id: 1402,
        meta: { title: i18n.global.t('pages_router_018') },
        path: '/exchange/details',
        name: 'exchangeDetails',
        component: () => import('@/pages/currency-exchange/details')
      }
    ]
  },
  {
    order: 6,
    path: '/funds',
    name: 'funds',
    id: 15,
    icon: require('@/assets/icons/ic-pay.png'),
    meta: { title: i18n.global.t('pages_router_007') },
    component: MainPage,
    children: [
      {
        id: 1501,
        meta: { title: i18n.global.t('pages_router_021') },
        path: '/funds/inbounds',
        name: 'settleInbounds',
        component: () => import('@/pages/global-pay/inbounds')
      },
      {
        id: 1502,
        meta: { title: i18n.global.t('pages_router_036') },
        path: '/funds/outbounds',
        name: 'foreignOutbounds',
        component: () => import('@/pages/global-pay/outbounds')
      },
      {
        id: 1503,
        meta: { title: i18n.global.t('pages_router_027') },
        path: '/funds/review',
        name: 'settleReview',
        component: () => import('@/pages/global-pay/reviewer')
      },
      {
        id: 1504,
        meta: { title: i18n.global.t('pages_router_028') },
        path: '/funds/pay_details',
        name: 'payDetails',
        component: () => import('@/pages/global-pay/details')
      },
      {
        id: 1505,
        meta: { title: i18n.global.t('pages_router_034') },
        path: '/funds/payee_manage',
        name: 'payeeManage',
        component: () => import('@/pages/global-pay/payee')
      },
      {
        id: 1506,
        meta: { title: i18n.global.t('pages_router_029') },
        path: '/funds/withdraw',
        name: 'fundsWithdraw',
        component: () => import('@/pages/global-pay/withdraw')
      },
      {
        id: 1507,
        meta: { title: i18n.global.t('pages_router_030') },
        path: '/funds/account_transfer',
        name: 'accountTransfer',
        component: () => import('@/pages/global-pay/transfer')
      },
      {
        id: 1508,
        meta: { title: i18n.global.t('pages_router_024') },
        path: '/funds/inbounds/single_cny',
        name: 'singleCny',
        component: () => import('@/pages/global-pay/inbounds/single-apply')
      },
      {
        id: 1509,
        meta: { title: i18n.global.t('pages_router_025') },
        path: '/funds/inbounds/batch_cny',
        name: 'batchCny',
        component: () => import('@/pages/global-pay/inbounds/batch-apply')
      },
      {
        id: 1510,
        meta: { title: i18n.global.t('pages_router_026') },
        path: '/funds/apply_success',
        name: 'applyPaySuccess',
        component: () => import('@/pages/global-pay/inbounds/apply-success')
      },
      {
        id: 1511,
        meta: { title: i18n.global.t('pages_pay_204') },
        path: '/funds/inbounds/payee_edit',
        name: 'inboundPayeeEdit',
        component: () => import('@/pages/global-pay/payee/payee-inbounds/payee-edit')
      },
      {
        id: 1512,
        meta: { title: i18n.global.t('pages_router_035') },
        path: '/funds/payer_manage',
        name: 'payerManage',
        component: () => import('@/pages/global-pay/inbounds/payer')
      },
      {
        id: 1513,
        meta: { title: i18n.global.t('pages_router_025') },
        path: '/funds/outbounds/batch_apply',
        name: 'batchOutbounds',
        component: () => import('@/pages/global-pay/outbounds/batch-apply')
      },
      {
        id: 1514,
        meta: { title: i18n.global.t('pages_router_024') },
        path: '/funds/outbounds/single_apply',
        name: 'singleOutbounds',
        component: () => import('@/pages/global-pay/outbounds/single-apply')
      },
      {
        id: 1515,
        meta: { title: i18n.global.t('pages_pay_305') },
        path: '/funds/outbounds/payee_edit',
        name: 'outboundPayeeEdit',
        component: () => import('@/pages/global-pay/payee/payee-outbounds/payee-edit')
      }
    ]
  },
  {
    order: 7,
    path: '/account',
    name: 'account',
    id: 16,
    icon: require('@/assets/icons/ic-finance.png'),
    meta: { title: i18n.global.t('pages_router_014') },
    component: MainPage,
    children: [
      {
        id: 1601,
        meta: { title: i18n.global.t('pages_router_015') },
        path: '/account/details',
        name: 'accountDetails',
        component: () => import('@/pages/account-manage/details')
      },
      {
        id: 1602,
        meta: { title: i18n.global.t('pages_router_019') },
        path: '/account/fee_details',
        name: 'feeDetails',
        component: () => import('@/pages/account-manage/fee')
      },
      {
        id: 1603,
        meta: { title: i18n.global.t('pages_router_020') },
        path: '/account/records',
        name: 'accountRecords',
        component: () => import('@/pages/account-manage/records')
      }
    ]
  },
  {
    order: 8,
    path: '/user',
    name: 'userManage',
    id: 17,
    icon: require('@/assets/icons/ic-manage.png'),
    meta: { title: i18n.global.t('pages_router_023') },
    component: MainPage,
    children: [
      {
        id: 1701,
        meta: { title: i18n.global.t('pages_router_031') },
        path: '/user/account_materials',
        name: 'userAccountMaterials',
        component: () => import('@/pages/user-manage/materials')
      },
      {
        id: 1702,
        meta: { title: i18n.global.t('pages_router_032') },
        path: '/user/user_manage',
        name: 'userAccountManage',
        component: () => import('@/pages/user-manage/manage')
      },
      {
        id: 1703,
        meta: { title: i18n.global.t('pages_router_033') },
        path: '/user/account_security',
        name: 'userAccountSecurity',
        component: () => import('@/pages/user-manage/safety')
      }
    ]
  },
  {
    order: 3,
    path: '/receipt',
    name: 'receipt',
    id: 18,
    icon: require('@/assets/icons/ic-receipt.png'),
    meta: { title: i18n.global.t('pages_router_050') },
    component: MainPage,
    children: [
      {
        id: 1801,
        meta: { title: i18n.global.t('pages_router_049') },
        path: '/receipt/orders',
        name: 'receiptOrders',
        component: () => import('@/pages/receipt-orders')
      }
    ]
  }
]

export default routes
