import axios from '../request'
import qs from 'qs'
import { commonFormatDown } from '@/util'

// 换汇币种列表
export const getSupRateCur = () => {
  return axios({
    url: `/exchange/getSupRateCur`,
    method: 'post'
  })
}

// 汇率获取
export const getExchangeRate = (data) => {
  return axios({
    url: `/exchange/getExchangeRate`,
    method: 'post',
    data
  })
}

// 用户锁汇权限
export const getUserLockRateAuth = (data) => {
  return axios({
    url: `/exchange/queryLockRateAuth`,
    method: 'post',
    data
  })
}

// 换汇申请
export const exchangeApply = (data) => {
  return axios({
    url: `/exchange/exchangeApply`,
    method: 'post',
    data
  })
}

// 交易时间段
export const getTradeDate = () => {
  return axios({
    url: `/basic/queryBusinessDate`,
    method: 'post'
  })
}

// 换汇列表
export const getExchangeList = (data) => {
  return axios({
    url: `/exchange/getExchangeList`,
    method: 'post',
    data
  })
}

// 换汇列表导出
export const exportExchange = async (data) => {
  const res = await axios({
    url: `/exchange/downloadExchangeList`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}

// 下载换汇回单
export const downExchangeReceipt = async (data) => {
  const res = await axios({
    url: `/exchange/downloadExchangeNotify`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}
