<template>
  <a-config-provider :locale="$i18n.locale === 'en' ? enUS : zhCN">
    <router-view v-if="haveConfig" />
  </a-config-provider>
</template>

<script>
import { ref, provide, reactive } from 'vue'
import enUS from 'ant-design-vue/es/locale/en_US'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import { ConfigProvider } from 'ant-design-vue'
import { setTheme } from './styles/theme'
import { themes } from './styles/theme/themeData'
import instance from './api/axios'
import axios from './api/request.js'

export default {
  name: 'App',
  components: {
  },
  setup() {
    const haveConfig = ref(false)
    const ottApiUrl = reactive({
      local: 'x',
      development: 'https://portal-api-uat.sprout-pay.com',
      sandbox: 'x',
      test2: 'x',
      uat: 'https://portal-api-uat.sprout-pay.com',
      production: 'https://portal-api.sprout-pay.com'
    })

    const handleApiUrl = (configUrl) => {
      console.log('configUrl:', configUrl)
      // if prod url, dynamic change according frontend url
      if (configUrl.includes('.sprout-pay.com')) {
        let frontendUrl = window.location.origin
        console.log('frontend url:', frontendUrl)
        frontendUrl = frontendUrl.replace('//portal.', '//portal-api.')
        const apiUrl = configUrl.replace('portal-api.sprout-pay.com', frontendUrl.split('//')[1])
        return apiUrl
      }
      return configUrl
    }

    const projectData = ref({
      saasMerNameZh: 'Union Goldenpay (HK) Limited',
      saasMerNameEn: 'Union Goldenpay (HK) Limited',
      email: 'cs@sprout-pay.com',
      website: '#',
      copyRight: '@Copyright 2024 Union Goldenpay (HK) Limited. All rights reserved',
      address: '',
      themeKey: 'default',
      themeColor: '#1890FF',
      electronicReceipt: true,
      openReceipt: true,
      supply: false,
      suffix: '',
      faviconList: 'favicon.png',
      darkLogoList: 'login-logo-urtrade.png',
      lightLogoList: 'menu-logo-urtrade.png',
      bgList: 'login-banner-urtrade.jpg',
      apiUrl: handleApiUrl(ottApiUrl[process.env.NODE_ENV])
    })
    provide('$themeData', projectData)

    const setFavicon = (url) => {
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      link.href = url
      document.getElementsByTagName('head')[0].appendChild(link)
    }

    const init = async () => {
      if (process.env.VUE_APP_SAASNAME !== 'OTT') {
        const baseURL = process.env.VUE_APP_SAAS_CONFIGURL

        const { data } = await instance.post(`${baseURL}/saas/getSaasMerConfig`)
        if (data.code === 'S00000') {
          projectData.value = {
            saasMerNameZh: data.data.saasMerNameZh,
            saasMerNameEn: data.data.saasMerNameEn,
            ...JSON.parse(data.data.frontJson)
          }
        } else {
          return false
        }
      }
      // setTheme(projectData.value.themeKey)
      setTheme(themes[process.env.VUE_APP_SAASNAME] ? process.env.VUE_APP_SAASNAME : 'default')
      setFavicon(projectData.value.faviconList)

      ConfigProvider.config({
        // theme: themes[projectData.value.themeKey] || themes[process.env.VUE_APP_SAASNAME] || themes['default']
        theme: themes[process.env.VUE_APP_SAASNAME] || themes['default']
      })

      axios.defaults.baseURL = projectData.value.apiUrl
      haveConfig.value = true
    }

    init()

    return {
      enUS,
      zhCN,
      haveConfig
    }
  }
}

</script>

<style>
#app {
  font-family: roboto,PingFang SC,-apple-system,BlinkMacSystemFont,Segoe UI,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
}
</style>
