<template>
  <div id="mainPage">
    <left-menu />
    <div id="rightMenu">
      <div class="menu-header flex-align">
        <!-- <a-tooltip :title="$store.getters.getUserInfo.merchantName">
          <div class="merchant-name">{{ $t('pages_main_053') }}{{ $store.getters.getUserInfo.merchantName }}</div>
        </a-tooltip> -->
        <!-- <a-button
          v-if="$store.getters.curList.length !== 0"
          shape="round"
          type="primary"
          style="font-size: 13px; margin-left: 32px"
          @click="$router.push('/wallet/index')"
        >{{ $t('pages_main_027') }}</a-button> -->
        <div style="margin-left: auto" class="flex-align">
          <!-- <schedule-outlined class="ic-global" style="margin: 0 36px" />
          <bell-outlined class="ic-global" /> -->
          <!-- <div v-if="!['港勤支付', 'ALREMIT'].includes(themeData.main.name)" class="flex-align help-center" :onclick="'window.open(\''+'http://help.msp.ottpayhk.com/'+'?base='+ baseUrl+'\')'">
            <img src="~@/assets/svgs/help.svg">
            <span>{{ $t('pages_main_023') }}</span>
          </div> -->
          <div class="company-name">{{ $t('pages_main_053') }}{{ $store.getters.getUserInfo.merchantName }}</div>
          <img class="user-header" src="@/assets/images/user-header.png">
          <a-dropdown>
            <a class="merchant-email" @click.prevent>
              {{ $store.getters.getUserInfo.email }}
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <a @click="$router.push('/user/account_materials')">{{ $t('pages_router_031') }}</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="$router.push('/user/user_manage')">{{ $t('pages_router_032') }}</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="$router.push('/user/account_security')">{{ $t('pages_router_033') }}</a>
                </a-menu-item>
                <!-- <a-menu-item v-if="!['港勤支付', 'ALREMIT'].includes(themeData.main.name)">
                  <a :onclick="'window.open(\''+'http://help.msp.ottpayhk.com/'+'?base='+ baseUrl+'\')'">{{ $t('pages_main_023') }}</a>
                </a-menu-item> -->
                <a-menu-item>
                  <a @click="signOut">{{ $t('pages_main_028') }}</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <global-outlined class="ic-global" style="margin-top: 10px" />
          <a-dropdown :trigger="['click']">
            <a class="main-text-color" style="margin-top: 10px" @click.prevent>
              {{ $i18n.locale === 'en' ? 'English' : '中文' }}
              <DownOutlined />
            </a>
            <template #overlay>
              <a-menu @click="languageChange">
                <a-menu-item key="zh" :disabled="$i18n.locale === 'zh'">
                  <a>中文</a>
                </a-menu-item>
                <a-menu-item key="en" :disabled="$i18n.locale === 'en'">
                  <a>English</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
      <div id="menuContent" class="menu-content" :style="{width: clientWidth < 800 ? '1280px' : 'auto'}">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs, inject, onUnmounted, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import LeftMenu from './left-menu/index'
import { _login } from '@/api/index'
import { getCountryLogo, parseMoney, localStorageClear } from '@/util'
import { useStore } from 'vuex'
import Cookies from 'js-cookie'
export default defineComponent({
  name: 'MainPage',
  components: {
    'left-menu': LeftMenu
  },
  setup() {
    let timer = null
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      themeData: inject('$themeData'),
      clientWidth: 0,
      currentTime: new Date().getTime(),
      baseUrl: window.location.protocol + '//' + window.location.host
    })
    onMounted(() => {
      state.clientWidth = document.documentElement.clientWidth
    })
    onUnmounted(() => {
      window.onmousemove = null
      clearInterval(timer)
    })
    const init = async () => {
      loadMouseWatch()
      loadTimer()
      store.dispatch('getCurList')
    }
    const signOut = async () => {
      await _login.loginOut()
      Cookies.remove('last_page_name')
      localStorageClear()
      router.replace('/login')
    }
    const languageChange = async (e) => {
      localStorage.setItem('locale-language', e.key)
      await store.dispatch('getLanguage')
      location.reload()
    }
    const loadTimer = () => {
      timer = setInterval(() => {
        loadIsExpired()
      }, 5000)
    }
    const loadIsExpired = () => {
      if (new Date().getTime() - state.currentTime > 5 * 1000 * 60) {
        clearInterval(timer)
        router.push('/user_leave')
      }
    }
    const loadMouseWatch = () => {
      window.onmousemove = () => {
        state.currentTime = new Date().getTime()
      }
    }
    init()
    return {
      getCountryLogo,
      parseMoney,
      languageChange,
      signOut,
      init,
      ...toRefs(state)
    }
  }
})
</script>
<style lang="less" scoped>
  #mainPage {
    display: flex;
    #rightMenu {
      width: 100%;
      background: #F8F9FB;
      height: 100vh;
      overflow-y: scroll;
      .menu-header {
        background: #fff;
        padding: 8px 24px 8px 8px;
        white-space: nowrap;
        height: 60px;
        position: relative;
        align-items: center;
        border-bottom: 1px solid #E8E8E8;
        .company-name {
          margin: 10px 24px 0 8px;
          font-size: 13px;
          text-align: left;
          color: #1F223E;
          position: relative;
        }
        .merchant-name {
          font-size: 16px;
          font-weight: 600;
          color: #000;
          position: absolute;
          right: 28px;
          top: 8px;
          width: 400px;
          text-align: right;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .help-center {
          font-size: 13px;
          color: #1F223E;
          margin-top: 16px;
          cursor: pointer;
          img {
            width: 18px;
            margin: 0 5px 0 24px;
          }
        }
        .user-header {
          width: 30px;
          height: 30px;
          margin: 10px 0 0 24px;
        }
        .merchant-email {
          margin: 10px 24px 0 8px;
          font-size: 13px;
          text-align: left;
          color: #1F223E;
          position: relative;
        }
      }
      .menu-content {
        padding: 18px 20px;
        height: calc(100vh - 75px);
        overflow-y: scroll;
        background-color: #fff;
      }
    }
  }
</style>

