import { _login } from '@/api'
const map = {
  state: {
    systemMap: null,
    merchantKyc: null,
    routeRefresh: true,
    frontProVersion: ''
  },
  getters: {
    statusAll(state) {
      return state.systemMap || (localStorage.getItem('systemMap') ? JSON.parse(localStorage.getItem('systemMap')) : state.systemMap)
    },
    getMerchantKyc(state) {
      return state.merchantKyc || (localStorage.getItem('merchantKyc') ? JSON.parse(localStorage.getItem('merchantKyc')) : state.merchantKyc)
    }
  },
  mutations: {
    setSystemMap (state, obj) {
      state.systemMap = obj
      localStorage.setItem('systemMap', JSON.stringify(obj))
    },
    setMerchantKyc (state, obj) {
      state.merchantKyc = obj
      localStorage.setItem('merchantKyc', JSON.stringify(obj))
    },
    setFrontVersion (state, v) {
      state.frontProVersion = v
    }
  },
  actions: {
    async getLanguage({ commit }) {
      const res = await _login.getLanguage()
      if (res.data.succ) commit('setSystemMap', res.data.data)
    }
  }
}

export default map
