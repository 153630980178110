import axios from '../request'
import qs from 'qs'
import { commonFormatDown } from '@/util'

// 今日订单列表
export const getTodayOrders = (data) => {
  return axios({
    url: `/acquire/queryTodayOrders`,
    method: 'post',
    data
  })
}

// 历史订单列表
export const getHistoryOrders = (data) => {
  return axios({
    url: `/acquire/queryOrdersByPage`,
    method: 'post',
    data
  })
}

// 订单详情
export const getOrderDetail = (data) => {
  return axios({
    url: `/acquire/queryOrderDetail`,
    method: 'post',
    data
  })
}

// 订单导出
export const exportAcquireRecordExcel = async (data) => {
  const res = await axios({
    url: `/acquire/exportAcquireRecordExcel`,
    method: 'post',
    data: qs.stringify(data),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob'
  })
  commonFormatDown(res)
}

