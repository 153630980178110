import { createApp } from 'vue'
import App from './App.vue'
import i18n from './locale/index'
import Antd from 'ant-design-vue'
import { message } from 'ant-design-vue'
import routers from './router/index'
import store from './store/index'
import { _home } from './api'
import * as Icons from '@ant-design/icons-vue'
import 'ant-design-vue/dist/antd.variable.min.css'

const app = createApp(App)

for (const i in Icons) {
  app.component(i, Icons[i])
}

// 加载前端静态配置文件
async function getConfig() {
  const res = await _home.getConfig()
  if (res && res.frontProVersion) store.commit('setFrontVersion', res.frontProVersion)
}

message.config({
  top: '78px',
  duration: 5
})

app
  .use(Antd)
  .use(i18n)
  .use(routers)
  .use(store)
  .mount('#app')

if (process.env.NODE_ENV !== 'development') getConfig()
