
import { createStore } from 'vuex'
import user from './modules/user'
import menu from './modules/menu'
import map from './modules/map'

const store = createStore({
  state() {},
  modules: {
    user,
    menu,
    map
  }
})

export default store
