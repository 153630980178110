import axios from '../request'

// 获取商户提交信息
export const getAcctRegisterInfo = (data) => {
  return axios({
    url: `/account/queryAcctRegisterInfo`,
    method: 'post',
    data
  })
}

// 添加用户获取验证码
export const getMspVerificationCode = (data) => {
  return axios({
    url: `/account/getMspVerificationCode`,
    method: 'post',
    data
  })
}

// 系统支持的语言
export const getSysLanguage = (data) => {
  return axios({
    url: `/account/getSysLanguage`,
    method: 'post',
    data
  })
}

// 更新账户信息
export const updateAcctInfo = (data) => {
  return axios({
    url: `/account/updateAcctInfo`,
    method: 'post',
    data
  })
}

// 获取用户列表
export const getUserList = (data) => {
  return axios({
    url: `/account/getUserList`,
    method: 'post',
    data
  })
}

// 获取手机号区域
export const getPhoneAreaCode = (data) => {
  return axios({
    url: `/basic/queryPhoneAreaCode`,
    method: 'post',
    data
  })
}

// 获取角色ID
export const getUserRoleName = (params) => {
  return axios({
    url: `/account/queryRoleById`,
    method: 'get',
    params
  })
}

// 新增/修改用户信息
export const addOrUpdateUserManage = (data) => {
  return axios({
    url: `/account/addOrUpdateUserManage`,
    method: 'post',
    data
  })
}

// 重置用户密码
export const resetPassword = (params) => {
  return axios({
    url: `/account/resetPassWord`,
    method: 'get',
    params
  })
}

// 获取邮件验证码
export const sendCodeEmail = () => {
  return axios({
    url: `/user/sendPayPwdEmail`,
    method: 'get'
  })
}

// 修改登录密码
export const modifyPassword = (data) => {
  return axios({
    url: `/account/updatePwd`,
    method: 'post',
    data
  })
}

// 设置支付密码
export const setPayPassword = (data) => {
  return axios({
    url: `/user/setPayPassword`,
    method: 'post',
    data
  })
}

// 修改支付密码
export const modifyPayPassword = (data) => {
  return axios({
    url: `/user/changePayPassword`,
    method: 'post',
    data
  })
}

// 支付密码重置
export const resetPayPassword = (data) => {
  return axios({
    url: `/user/resetPayPwd`,
    method: 'post',
    data
  })
}

// 更新账户信息
export const updateKycInfo = (data) => {
  return axios({
    url: `/account/updateKycInfo`,
    method: 'post',
    data
  })
}

// 获取当前用户已保存信息
export const getTemporaryValue = (data) => {
  return axios({
    url: `/basic/getTemporaryValue`,
    method: 'post',
    data
  })
}

// 用户信息暂存
export const setTemporaryValue = (data) => {
  return axios({
    url: `/basic/setTemporaryValue`,
    method: 'post',
    data
  })
}

// 导出商户入网资料模板
export const exportMerchantTemplate = (params) => {
  return axios({
    url: `/user/exportMerchantTemplate`,
    method: 'get',
    params
  })
}

// 获取入网拒绝信息
export const getRejectCustomerKyc = (data) => {
  return axios({
    url: `/merchantRefactor/queryCustomerKyc`,
    method: 'post',
    data
  })
}

// 提交入网商户信息
export const approveMaterial = (data) => {
  return axios({
    url: `/merchantRefactor/approveMaterial`,
    method: 'post',
    data
  })
}

// 根据商户code获取协议
export const getSaasMerAgreement = (data) => {
  return axios({
    url: data.id ? `/saas/getSaasMerAgreement/${data.id}` : '/saas/getSaasMerAgreement',
    method: 'post',
    data
  })
}
