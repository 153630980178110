import routes from './routes'
import { createRouter, createWebHashHistory } from 'vue-router'

const notLinkOpts = [
  'login', 'oauthLogin', 'oauthSubmit', 'homeIndex', 'register', 'termsService', 'clauseSupply', 'onlineFix',
  'privacyPolicy', 'usePolicy', 'forgetPassword', 'resetPassword', 'setPassword', 'agreement'
]

const routers = createRouter({
  history: createWebHashHistory(),
  routes
})

routers.beforeEach((to, from, next) => {
  if (!localStorage.getItem('userInfo') && !notLinkOpts.includes(to.name)) {
    next({ name: 'login' })
  } else if (localStorage.getItem('userInfo') && to.name === 'login') { // 已经登录且前往的是登录页
    const query = to.query || null
    if (query && query.source && query.source === 'oms') { // 来源是oms
      localStorage.clear()
      next({ name: 'login', query: { source: 'oms' }})
    } else { // 来源不是oms 正常操作
      next({ name: 'homeIndex' })
    }
  } else if (localStorage.getItem('userInfo') && to.path === '/') {
    next({ name: 'homeIndex' })
  } else {
    next()
  }
})

routers.afterEach((to) => {
  document.title = to.meta.title
  window.scrollTo(0, 0)
})

export default routers
