import axios from '../request'

export function getConfig () {
  return axios.get(`${window.location.origin}/mspFrontendConfig.json`)
}

// 上线监听
export function userHealth (data) {
  return axios({
    url: `/user/health`,
    method: 'post',
    data
  })
}

userHealth

// 币种余额
export function getCurrency (data) {
  return axios({
    url: `/user/queryCurList`,
    method: 'post',
    data
  })
}

// 用户信息
export function getMerchantInfo (data) {
  return axios({
    url: `/merchantRefactor/queryKycStatus`,
    method: 'post',
    data
  })
};

// home页走势图
export function getExchangeCanvas (params) {
  return axios({
    url: `/user/getRateHistory`,
    method: 'get',
    params
  })
};

// 首页统计图表
export function getAmountRatio (data) {
  return axios({
    url: `/statistical/amountRatio`,
    method: 'post',
    data
  })
};

// 查询交易金额
export function getTradeRatio (data) {
  return axios({
    url: `/statistical/tradeRatio`,
    method: 'post',
    data
  })
}

// 业务信息统计
export function getBusStatistics (data) {
  return axios({
    url: `/user/queryBusStatistics`,
    method: 'post',
    data
  })
};

// 签署协议
export const updateAgreement = (data) => {
  return axios({
    url: `/merchantRefactor/addOrUpdateAgreementManage`,
    method: 'post',
    data
  })
}

// 钱包余额统计
export const getWalletRatio = () => {
  return axios({
    url: `/statistical/walletRatio`,
    method: 'post'
  })
}

// 获取通知内容
export const getNoticeInfo = (params) => {
  return axios({
    url: `/user/getHomeNotice`,
    method: 'get',
    params
  })
}
