<template>
  <div id="headerNav" class="flex-align">
    <div class="area" />
    <div class="title">{{ content }}</div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'HeaderNav',
  props: {
    content: {
      type: String,
      default() {
        return ''
      }
    }
  }
})
</script>
<style lang="less" scoped>
#headerNav {
  text-align: left;
  .area {
    width: 3px;
    height: 16px;
    background: @main-color;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-left: 5px;
  }
}
</style>

