const user = {
  state: {
    userInfo: {}
  },
  getters: {
    getUserInfo(state) {
      return state.userInfo.id ? state.userInfo : localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : state.userInfo
    }
  },
  mutations: {
    updateUserInfo(state, record) {
      localStorage.setItem('userInfo', JSON.stringify(record))
      state.userInfo = record
    }
  }
}

export default user
