import { createI18n } from 'vue-i18n'
import enUS from 'ant-design-vue/es/locale/en_US'
import zhCN from 'ant-design-vue/es/locale/zh_CN'

const zhJSON = require('./zh.json')
const enJSON = require('./en.json')

const LOCALE_KEY = 'locale-language'

const saasName = process.env.VUE_APP_SAASNAME

if (saasName === 'URTRADE') dealAttrs('URTRADE')

function dealAttrs(attr) {
  for (const k in zhJSON) {
    if (zhJSON[k].indexOf('SPROUT') !== -1) {
      const spiltArr = zhJSON[k].split('SPROUT')
      zhJSON[k] = spiltArr.join(attr)
    }
  }
  for (const k in enJSON) {
    if (enJSON[k].indexOf('SPROUT') !== -1) {
      const spiltArr = enJSON[k].split('SPROUT')
      enJSON[k] = spiltArr.join(attr)
    }
  }
}

const locales = {
  zh: Object.assign(zhJSON, zhCN),
  en: Object.assign(enJSON, enUS)
}

let language = localStorage.getItem(LOCALE_KEY)
if (!language) language = (navigator.language || navigator.browserLanguage).toLowerCase().substr(0, 2)
if (!language || language === 'null' || language === 'undefined') language = 'zh'
localStorage.setItem(LOCALE_KEY, language)

const i18n = createI18n({
  locale: language,
  messages: locales,
  legacy: false,
  globalInjection: true
})

export default i18n

